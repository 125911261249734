<template>
    <div class="page-box">
        <van-nav-bar class="nav-style1" title="实名认证服务" fixed placeholder safe-area-inset-top />
        <div class="person-info">
            认证信息：{{name}}（{{idcard}}）
        </div>
        <div class="succ-img">
            <img v-if="res == 'true'" src="../../assets/webicon.png" alt="">
            <img v-else src="../../assets/fail.png" alt="">
            <div class="tips" v-if="res == 'true'">您本次的个人认证已通过，点击完成返回</div>
            <div class="tips" v-else>您本次的个人认证已失败，点击完成返回</div>

            <div class="btn-style" @click='link'>完成</div>
        </div>
    </div>
</template>
<script>
import { Toast ,NavBar } from 'vant';
export default {
    data(){
        return{
            res:"",
            name:'',
            idcard:""        
        }
    },
    methods: {
        getQueryVariable(variable){
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
                if(pair[0] == variable){return pair[1];}
            }
        },
        link(){
            let ua = navigator.userAgent;
            // let isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1;
            let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            // console.log(isAndroid, '安卓')
            // console.log(isiOS, 'ios')
            let params = {
                res: this.res,
            };
            if (isiOS) {
                console.info('进入')
                window.webkit.messageHandlers.pushAppAction.postMessage(JSON.stringify(params));
            }
        }
    },
    mounted() {
        this.res = this.getQueryVariable('res')
        this.name = decodeURIComponent(this.getQueryVariable('name'))
        this.idcard = this.getQueryVariable('idCard')

    },
    components:{
        [Toast.name]: Toast,
        [NavBar.name]: NavBar,
    }
}
</script>

<style lang="scss" scoped>
   .page-box{
    height: 100vh;
    background-color: #fff;
    overflow: hidden;
    .nav-style1{
        background: #fff;
    }
    }
    .succ-img{
    text-align: center;
    margin-top: 100px;
    }
    .succ-img img{
        width: 90px;
        height: 70px;
    }
    .succ-img .tips{
    font-size: 18px;
    // font-weight: bold;
    color: #000000;
    margin-top: 15px;
    padding: 0 50px;
    }
    .back{
    width: 260px;
    height: 50px;
    background: #1F83D5;
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    line-height: 50px;
    margin: 150px auto 0;
    }
    .person-info{
        font-size: 14px;
        margin: 20px 20px 0;    
        border-radius: 5px;
        background: #E9F6FE;
        border: 1px solid #A7CFF1;
        padding: 5px 0 5px 10px;
        
    }
    .btn-style{
        margin: 30px 20px 0;
        background: #3373D7;
        color: #fff;
        padding: 10px 0;
        text-align: center;
        border-radius: 5px;
    }
</style>